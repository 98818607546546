<template>
  <ckeditor
    :value="value"
    @input="$emit('input', $event)"
    :config="editorConfig"
  ></ckeditor>
</template>

<script>
import CKEditor from "ckeditor4-vue";
export default {
  components: { ckeditor: CKEditor.component },
  props: ["value", "cleanMask"],
  data() {
    return {
      editorConfig: {
        extraAllowedContent: "div(*)[*]",
        disableNativeSpellChecker: false,
        removeButtons:
          "ImageButton,HiddenField,Flash,Smiley,Iframe,About,Source,Anchor",
      },
    };
  },
  methods: {},
};
</script>

<style>
</style>