<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
    @save="save"
  >
    <template #content>
      <v-container fluid>
        <v-row>
          <v-col>
            <ExamModeSelect
              v-model="form.ExamModeId"
              :fromExamForm="false"
              :allowCreate="true"
              :newExamModeOption="examModeOption"
              @new-exam-mode="openModeFormDialog"
              class="required"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <MaskNameField v-model="form.name" class="required" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="required label-mask">
            <label>Máscara:</label>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <MaskTextField v-model="form.mask" :cleanMask="cleanMask" />
          </v-col>
        </v-row>
      </v-container>

      <!-- modal de cadastrar nova modalidade -->
      <v-dialog v-model="examModeFormDialog" max-width="400">
        <ExameModesForm
          :mode="'insert'"
          :modalTitle="'Cadastrar modalidade'"
          @close="examModeFormDialog = false"
          @insert-completed="afterInsertExamMode"
        />
      </v-dialog>
    </template>

    <template #actions></template>
  </BaseForm>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import errorHandler from "@/helpers/error_handler";

import BaseForm from "@/components/template/BaseForm";
import ExameModesForm from "@/views/ExamModes/ExamModesForm.vue";

import MaskNameField from "@/components/template/FormElements/Masks/MaskNameField";
import MaskTextField from "@/components/template/FormElements/Masks/MaskTextField";
import ExamModeSelect from "@/components/template/FormElements/Masks/ExamModeSelect";

export default {
  components: {
    BaseForm,
    ExameModesForm,
    ExamModeSelect,
    MaskNameField,
    MaskTextField,
  },
  mixins: [formMixin],
  directives: {},
  computed: {
    examModeMaskId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      loading: false,
      cleanMask: false,
      examModeFormDialog: false,
      examModeOption: {},
      form: {},
    };
  },
  methods: {
    save(actionAfterOperation) {
      const errors = this.validateForm();

      if (errors.length) {
        errors.forEach((e) => {
          console.log(`${e}`);
        });
      } else {
        this[this.mod](actionAfterOperation);
      }
    },
    async insert(actionAfterOperation) {
      try {
        this.loading = true;

        const response = await this.$axios.post(`/exam-modes-masks`, this.form);

        this.loading = false;

        console.log("Operação concluída com sucesso");

        this.cleanMask = true;

        this.afterInsertion(actionAfterOperation, {
          ...this.form,
          id: response.data.id,
        });
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async update(actionAfterUpdate) {
      try {
        this.loading = true;

        await this.$axios.put(
          `/exam-modes-masks/${this.examModeMaskId}`,
          this.form
        );
        this.loading = false;

        this.afterUpdate(actionAfterUpdate);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadMaskData() {
      try {
        this.loading = true;

        const response = await this.$axios.get(
          `/exam-modes-masks/${this.examModeMaskId}`
        );

        this.setMaskData(response.data);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setMaskData(data) {
      this.form = { ...data };

      this.loading = false;
    },
    validateForm() {
      const errors = [];

      if (!this.form.ExamModeId) errors.push("Campo 'Modalidade' obrigatório!");

      if (!this.form.name) errors.push("Nome obrigatório!");

      if (!this.form.mask) errors.push("Máscara obrigatória!");

      return errors;
    },
    openModeFormDialog() {
      this.examModeFormDialog = true;
    },
    afterInsertExamMode(data) {
      this.examModeOption = { value: data.id, text: data.name };

      this.form.ExamModeId = data.id;

      this.examModeFormDialog = false;
    },
  },
  created() {
    if (this.mod === "update") this.loadMaskData();
  },
};
</script>

<style>
.label-mask {
  font-size: 1.05rem;
}
</style>
