<template>
  <v-text-field
    :value="value"
    @input="$emit('input', $event)"
    label="Nome:"
  >
  </v-text-field>
</template>

<script>
export default {
  props: ["value"],
};
</script>

<style>
</style>